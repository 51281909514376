import {HiShieldCheck} from 'react-icons/hi';
import { MdCancel, MdAnalytics } from 'react-icons/md';

const data = [
    {
        icon: <HiShieldCheck/>,
        heading : "Beast Intrest Rated in Market",
        detail:"Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat occaecat ut occaecat consequat est minim minim esse tempor laborum consequat esse adipisicing eu reprehenderit enim."
    },
    {
        icon: <MdCancel/>,
        heading : "Top Mostbooked on the market",
        detail:"Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat occaecat ut occaecat consequat est minim minim esse tempor laborum consequat esse adipisicing eu reprehenderit enim."
    },
    {
        icon: <MdAnalytics/>,
        heading : "Beast Price on the Market",
        detail:"Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat occaecat ut occaecat consequat est minim minim esse tempor laborum consequat esse adipisicing eu reprehenderit enim."
    }
  
]
export default data;