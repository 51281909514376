import React from 'react'
import './CopyRight.css'
const CopyRight = () => {
  return (
    <div className="cr-wrapper">
       allright reserved &#169; sksonu8544@gmail.com 
    </div>
  )
}

export default CopyRight